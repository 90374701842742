import React, { useState } from 'react';
import classNames from 'classnames';
import { toast, ToastContainer } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

import SectionHeader from '../components/sections/partials/SectionHeader';
import Button from '../components/elements/Button';
import Formater from '../utils/Formater';
import Validate from '../utils/Validate';
import api from '../service/ApiHandler';

var captchaRef;

const Contato = ({ className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, invertMobile, invertDesktop, alignTop, imageFill, ...props }) => {
    const outerClasses = classNames('section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);
    const splitClasses = classNames(invertMobile && 'invert-mobile', invertDesktop && 'invert-desktop', alignTop && 'align-top');

    const sectionHeader = {
        title: 'Entre em contato',
        paragraph: <>
            {/* <strong>Tel:</strong>
            &nbsp;
            <a href="tel:1142251157" className="link">(11) 4225-1157</a>
            <br />
            <strong>E-mail:</strong>
            &nbsp;
            <a href="mailto:contato@cobremais.com.br" className="link">contato@cobremais.com.br</a> */}
        </>
    };
    
    const [loading, setLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [captcha, setCaptcha] = useState('');

    const enviarContato = async () => {
        if(!nome) toast.warning('Informe seu nome');
        else if(nome.length < 4) toast.warning('Informe um nome válido');
        else if(!email) toast.warning('Informe seu e-mail');
        else if(!Validate.validateEmail(email)) toast.warning('Informe um e-mail válido');
        else if(!telefone) toast.warning('Informe seu telefone');
        else if(!Validate.validateTelefone(telefone) && !Validate.validateCelular(telefone)) toast.warning('Informe um telefone válido');
        else if(!assunto) toast.warning('Informe o assunto');
        else if(assunto.length < 4) toast.warning('Informe um assunto válido');
        else if(!mensagem) toast.warning('Digite sua mensagem');
        else if(mensagem.length < 4) toast.warning('Mensagem muito curta');
        else if(!captcha) toast.warning('Resolva o captcha');
        else {
            setLoading(true);
            const response = await api.enviarContato({
                nome, email, telefone, assunto, mensagem, captcha
            });
            if(!response) toast.error('Erro de conexão');
            else if(response.error) toast.error(response.error);
            else if(response.warning) toast.warning(response.warning);
            else if(response.success){
                setTimeout(() => toast.success(response.success), 1500);
                setNome('');
                setEmail('');
                setTelefone('');
                setAssunto('');
                setMensagem('');
            } else toast.error('Erro de conexão');
            setTimeout(() => captchaRef.reset(), 500);
            setTimeout(() => setLoading(false), 1500);
        }
    };

    return (
        <>
            <ToastContainer />
            {loading?<div className="loading"></div>:<></>}
            <div style={{backgroundColor:'#252525', height: 80}}></div>
            <div className="contato"></div>
            <section
                id="contato"
                {...props}
                className={outerClasses}
            >
                <div className="container">
                    <SectionHeader data={sectionHeader} className="center-content cadastro-header" style={{marginBottom:-20}} />
                    <div className={splitClasses}>
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="nome" style={{fontSize: 14}}>Nome</label>
                                <input placeholder="Informe seu Nome" id="nome" style={{marginBottom: 10}} value={nome} onChange={(e)=>setNome(Formater.capitalizer(e.target.value))} />
                                <label htmlFor="documento" style={{fontSize: 14}}>E-mail</label>
                                <input placeholder="Informe seu e-mail" id="email" style={{marginBottom: 10}} value={email} onChange={(e)=>setEmail(Formater.formatEmail(e.target.value))} />
                                <label htmlFor="telefone" style={{fontSize: 14}}>Telefone</label>
                                <input placeholder="Informe seu telefone" id="telefone" style={{marginBottom: 10}} value={telefone} onChange={(e)=>setTelefone(Formater.formatTelefone(e.target.value))} />
                                <label htmlFor="assunto" style={{fontSize: 14}}>Assunto</label>
                                <input placeholder="Informe o assunto" id="assunto" style={{marginBottom: 10}} value={assunto} onChange={(e)=>setAssunto(e.target.value)} />
                            </div>
                            <div className="col-md-8">
                                <label htmlFor="mensagem" style={{fontSize: 14}}>Mensagem</label>
                                <textarea placeholder="Digite sua mensagem" id="mensagem" style={{height: 178, marginBottom: 20}} value={mensagem} onChange={(e)=>setMensagem(e.target.value)}></textarea>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <ReCAPTCHA
                                            sitekey="6Lcp09YZAAAAAJ_N1ETslom4uMCAfnFM0N1UubL4"
                                            badge="inline"
                                            ref={e => (captchaRef = e)}
                                            onChange={(e)=>setCaptcha(e)}
                                        />
                                    </div>
                                    <div className="col-xs-6" style={{textAlign: 'right'}}>
                                        <Button className="button-primary" onClick={enviarContato}>Enviar</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contato;