const { cpf, cnpj } = require('cpf-cnpj-validator');

const validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const validateCPF = (d) => {
    return cpf.isValid(d);
};

const validateCNPJ = (d) => {
    return cnpj.isValid(d);
};

const validateDocumento = (d) => {
    d = d.replace(/[^\d]+/g,'');
    console.log(d, validateCPF(d), validateCNPJ(d));
    if(d.length <= 11) return validateCPF(d);
    else return validateCNPJ(d);
};

const validateTelefone = (t) => {
    t = t.replace(/[^\d]+/g,'');
    return t.length === 10;
};

const validateCelular = (t) => {
    t = t.replace(/[^\d]+/g,'');
    return t.length === 11;
};

export default {
    validateDocumento,
    validateTelefone,
    validateCelular,
    validateEmail,
    validateCNPJ,
    validateCPF,
};