import React, { useState } from 'react';
import classNames from 'classnames';
import * as CEP_Consulta from 'cep-promise';
import { toast, ToastContainer } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import Pacotes from '../../components/sections/Pacotes';
import Formater from '../../utils/Formater';
import Validate from '../../utils/Validate';
import api from '../../service/ApiHandler';

var captchaRef;

const propTypes = { ...SectionSplitProps.types };
const defaultProps = { ...SectionSplitProps.defaults };
const Cadastro = ({ className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, invertMobile, invertDesktop, alignTop, imageFill, ...props }) => {
    const outerClasses = classNames('cadastro section', topOuterDivider && 'has-top-divider', bottomOuterDivider && 'has-bottom-divider', hasBgColor && 'has-bg-color', invertColor && 'invert-color', className);
    const splitClasses = classNames(invertMobile && 'invert-mobile', invertDesktop && 'invert-desktop', alignTop && 'align-top');
    const sectionHeader = { title: 'Quero Contratar', paragraph: '' };
    
    const [loading, setLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [documento, setDocumento] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [estado, setEstado] = useState('');
    const [captcha, setCaptcha] = useState('');

    const consultarCep = (e) => {
        e = e.replace(/\D/g , '');
        if(e.length !== 8) return false;
        setLoading(true);
        CEP_Consulta(e)
            .then((response) => {
                setTimeout(() => setLoading(false), 3000);
                setLogradouro(response.street);
                setCidade(response.city);
                setBairro(response.neighborhood);
                setEstado(response.state);
            })
            .catch((error) => {
                console.error(error);
                setLogradouro('');
                setCidade('');
                setBairro('');
                setEstado('');
                setTimeout(() => setLoading(false), 3000);
            });
    };

    const criarConta = async () => {
        if(!nome) toast.warning("Informe seu Nome ou Razão Social");
        else if(nome.length < 4) toast.warning("Nome inválido");
        else if(!documento) toast.warning("Informe o CPF ou CNPJ");
        else if(!Validate.validateDocumento(documento)) toast.warning("CPF ou CNPJ inválido");
        else if(!email) toast.warning("Informe seu e-mail");
        else if(!Validate.validateEmail(email)) toast.warning("E-mail inválido");
        else if(!celular) toast.warning("Informe seu celular");
        else if(!Validate.validateCelular(celular)) toast.warning("Celular inválido");
        else if(!cep) toast.warning("Informe seu CEP");
        else if(cep.length < 9) toast.warning("CEP inválido");
        else if(!logradouro) toast.warning("Informe seu Logradouro");
        else if(logradouro.length < 10) toast.warning("Logradouro inválido");
        else if(!numero) toast.warning("Informe o número");
        else if(!bairro) toast.warning("Informe seu Bairro");
        else if(bairro.length < 4) toast.warning("Bairro inválido");
        else if(!cidade) toast.warning("Informe sua Cidade");
        else if(cidade.length < 4) toast.warning("Cidade inválida");
        else if(!estado) toast.warning("Informe seu estado");
        else if(!captcha) toast.warning("Resolva o captcha");
        else {
            setLoading(true);
            const response = await api.criarUsuario({
                nome, documento: Formater.cleanNumber(documento), email, 
                celular: Formater.cleanNumber(celular), 
                cep: Formater.cleanNumber(cep), logradouro, 
                complemento, numero, bairro, cidade, estado, captcha
            });
            if(!response) toast.error('Erro de conexão');
            else if(response.error) toast.error(response.error);
            else if(response.warning) toast.warning(response.warning);
            else if(response.success){
                setTimeout(() => toast.success(response.success), 1500);
                setNome('');
                setDocumento('');
                setEmail('');
                setCelular('');
                setCep('');
                setLogradouro('');
                setNumero('');
                setComplemento('');
                setBairro('');
                setCidade('');
                setEstado('');
            } else toast.error('Erro de conexão');
            setTimeout(() => captchaRef.reset(), 500);
            setTimeout(() => setLoading(false), 1500);
            
        }
    };

    return (
        <section
            id="cadastro"
            {...props}
            className={outerClasses}
        >
            <ToastContainer />
            {loading?<div className="loading"></div>:<></>}
            <div className="container">
                <SectionHeader data={sectionHeader} className="center-content cadastro-header" style={{marginBottom:-20}} />
                <div className={splitClasses}>
                    {/*<Pacotes />*/}
                    {/* <br /> */}
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="nome" style={{fontSize: 14}}>Nome ou Razão Social</label>
                            <input placeholder="Informe seu Nome ou Razão Social" id="nome" value={nome} onChange={(e)=>setNome(Formater.capitalizer(e.target.value))} />
                            <br />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="documento" style={{fontSize: 14}}>CPF ou CNPJ</label>
                            <input placeholder="Informe o CPF ou CNPJ" id="documento" value={documento} onChange={(e)=>setDocumento(Formater.formatDocumento(e.target.value))} maxLength="18" />
                            <br />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email" style={{fontSize: 14}}>E-mail</label>
                            <input placeholder="Informe seu e-mail" id="email" value={email} onChange={(e)=>setEmail(Formater.lowerize(e.target.value))} />
                            <br />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="celular" style={{fontSize: 14}}>Celular</label>
                            <input placeholder="Informe seu celular" id="celular" value={celular} onChange={(e)=>setCelular(Formater.formatCelular(e.target.value))} />
                            <br />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="cep" style={{fontSize: 14}}>CEP</label>
                            <input placeholder="Informe seu CEP" id="cep" value={cep} onChange={(e)=>{setCep(Formater.formatCep(e.target.value));consultarCep(e.target.value);}} maxLength="9" />
                            <br />
                        </div>
                        <div className="col-md-12"></div>
                        <div className="col-md-5">
                            <label htmlFor="logradouro" style={{fontSize: 14}}>Logradouro</label>
                            <input placeholder="Informe seu Logradouro" id="logradouro" value={logradouro} onChange={(e)=>setLogradouro(e.target.value)} />
                            <br />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="numero" style={{fontSize: 14}}>Número</label>
                            <input placeholder="Informe o Número" id="numero" value={numero} onChange={(e)=>setNumero(e.target.value)} />
                            <br />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="complemento" style={{fontSize: 14}}>Complemento</label>
                            <input placeholder="Informe o Complemento" id="complemento" value={complemento} onChange={(e)=>setComplemento(e.target.value)} />
                            <br />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="bairro" style={{fontSize: 14}}>Bairro</label>
                            <input placeholder="Informe seu Bairro" id="bairro" value={bairro} onChange={(e)=>setBairro(e.target.value)} />
                            <br />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="cidade" style={{fontSize: 14}}>Cidade</label>
                            <input placeholder="Informe sua Cidade" id="cidade" value={cidade} onChange={(e)=>setCidade(e.target.value)} />
                            <br />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="estado" style={{fontSize: 14}}>Estado</label>
                            <select id="estado" value={estado} onChange={(e)=>setEstado(e.target.value)}>
                                <option value="">...</option>
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AP">AP</option>
                                <option value="AM">AM</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MT">MT</option>
                                <option value="MS">MS</option>
                                <option value="MG">MG</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PR">PR</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SP">SP</option>
                                <option value="SE">SE</option>
                                <option value="TO">TO</option>
                            </select>
                            <br />
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-xs-6">
                            <ReCAPTCHA
                                sitekey="6Lcp09YZAAAAAJ_N1ETslom4uMCAfnFM0N1UubL4"
                                badge="inline"
                                ref={e => (captchaRef = e)}
                                onChange={(e)=>setCaptcha(e)}
                            />
                        </div>
                        <div className="col-xs-6" style={{textAlign:'right'}}>
                            <Button className="button-primary" onClick={criarConta}>Quero Contratar</Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Cadastro.propTypes = propTypes;
Cadastro.defaultProps = defaultProps;

export default Cadastro;