import React from 'react';

const Erro404 = () => {
    return (
        <>
            <div style={{backgroundColor:'#252525', height: 80}}></div>
            <div className="erro404">
                <center>
                    <img src={require('../assets/images/404-permalink.png')} alt="..." width="230" />
                </center>
                <h5>Página não encontrada</h5>
            </div>
        </>
    );
};

export default Erro404;