import api from './api';

export default  {
    enviarContato: async (dados) => {
        var response = false;
        try {
            response = await api.post('/enviar-contato', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    criarUsuario: async (dados) => {
        var response = false;
        try {
            response = await api.post('/criar-usuario', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    listarPacotes: async () => {
        var response = false;
        try {
            response = await api.get('/listar-pacotes');
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
    loginUsuario: async (dados) => {
        var response = false;
        try {
            response = await api.post('/login-usuario', dados);
            response = response.data;
        } catch(e){ 
            response = false;
        }
        return response;
    },
};