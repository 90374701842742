import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from './App';
import './assets/css/App.css';
import './assets/css/bootstrap.min.css';
import './assets/scss/style.scss';
const history = createBrowserHistory();
ReactDOM.render(<Router history={history}><App /></Router>, document.getElementById('root'));
