import React from 'react';
import Modal from 'react-modal';
import Button from './Button';

Modal.setAppElement('#root');

const customStyles = {content : { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' }};
export default ({open, setOpen}) => {
    return (
        <div>
            <Modal
            isOpen={open}
            onAfterOpen={()=>{}}
            onRequestClose={()=>{}}
            style={customStyles}
            contentLabel="Example Modal"
            >
                <Button 
                    className="button-sm close"
                    onClick={()=>setOpen(false)}
                >
                    <img src={require('../../assets/images/close.svg')} height={14} width={14} alt="..." />
                </Button>
                <div style={{color: '#3EB649',fontWeight: 'bold',fontSize: 24,textAlign: 'left'}}>Recuperar Senha</div>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <input type="email" id="email-esqueci" placeholder="E-mail" style={{marginBottom: 20}} />
                </div>
                <center>
                    <Button 
                        className="button-sm button-primary text-white"
                        style={{width: '100%'}}
                    >Recuperar</Button>
                </center>
            </Modal>
        </div>
    )
}