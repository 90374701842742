import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';

const LogoRCA = ({
	className,
	...props
}) => {

	const classes = classNames(
		'brand',
		className
	);

	return (
		<div
			{...props}
			className={classes}
		>
			<h1 className="m-0" style={{display: 'flex', height: 50}}>
				<Image
					src={require('./../../../assets/images/logo.svg')}
					alt="Open"
					width={200}
					height={50}
					style={{ 
						display: 'inline-table !important',
						height: 50,
						width: 200
					}}
				/>
				<a href="https://rcagroup.com.br" target="_blank" rel="noopener noreferrer">
					<Image
						src={require('./../../../assets/images/rca_horizontal-negative.svg')}
						alt="Open"
						width={200}
						height={50}
						style={{ 
							display: 'inline-table !important',
							height: 50,
							width: 220 
						}}
					/>
				</a>
				{/* <a href="http://certificadora.xpertia.com.br" target="_blank" rel="noopener noreferrer">
					<Image
						src={require('./../../../assets/images/logo_negative.svg')}
						alt="Open"
						width={200}
						height={50} 
						style={{ 
							display: 'inline-table !important',
							height: 50,
							width: 180
						}}
					/>
				</a> */}
			</h1>
		</div>
	);
}

export default LogoRCA;