import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import LayoutDefault from './layouts/LayoutDefault';
import 'react-toastify/dist/ReactToastify.min.css'; 
//views
import Erro404 from './views/Erro404';
import Home from './views/Home';
import Contato from './views/Contato';
import Termos from './views/Termos';
import Politica from './views/Politica';
import LGPD from './views/LGPD';
ReactGA.initialize(process.env.REACT_APP_GA_CODE);
const trackPage = page => { ReactGA.set({ page }); ReactGA.pageview(page); };
var routeVars = [];
const routes = [
  { component: Home, path: '/', layout: LayoutDefault },
  { component: Contato, path: '/contato', layout: LayoutDefault },
  { component: Termos, path: '/termos', layout: LayoutDefault },
  { component: Politica, path: '/politica', layout: LayoutDefault },
  { component: LGPD, path: '/lgpd', layout: LayoutDefault },
];
for(const route of routes) routeVars[route.path] = 1;
const App = () => {
  const childRef = useRef();
  let location = useLocation();
  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
            {
              routes.map((row, index) => (
                <AppRoute key={index} exact path={row.path} component={row.component} layout={row.layout} />
              ))
            }
          {!routeVars[window.location.pathname]?<><AppRoute component={Erro404} layout={LayoutDefault} /></>:<></>}
        </Switch>
      )} />
  );
}

export default App;