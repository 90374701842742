import React from 'react';
import Button from '../elements/Button';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Link } from 'react-router-dom';

export default () => (
    <Slider direction="horizontal" autoplay={6000} infinite={true}>
        <div
			style={{ background: `url(${require('../../assets/images/slides/slide1.png')}) no-repeat center center` }}
		>
			<div className="center reveal-from-top" style={{paddingTop: 160}} data-reveal-delay="400">
                <h3 className="mt-0 mb-16">
                    <div style={{color: 'white',fontSize:14}}>Sistema de Cobrança Online</div>
                    <div className="text-color-primary" style={{marginTop:-10, fontSize: 36}}>CobreMais+</div>
                </h3>
                <p className="m-0 mb-32" style={{color: 'white'}}>
                    O melhor sistema de cobrança online. <br /> Realize suas cobranças rápido e fácil.
                </p>
                <Button tag="a" color="primary" wideMobile className="text-white" href="#cadastro">Quero Contratar</Button>
			</div>
		</div>
        <div
			style={{ background: `url(${require('../../assets/images/slides/slide2.png')}) no-repeat center center` }}
		>
			<div className="center" style={{paddingTop: 130}}>
				<h1 style={{color:'white'}}>Ainda está<br />com dúvidas?</h1>
                <Link className="button button-primary button-sm text-white" wideMobile to="/contato">Entre em Contato</Link>
			</div>
		</div>
        <div
			style={{ background: `url(${require('../../assets/images/slides/slide2.png')}) no-repeat center center` }}
		>
			<div className="center" style={{paddingTop: 130}}>
				<h1 style={{color:'white'}}>Ainda está<br />com dúvidas?</h1>
                <Link className="button button-primary button-sm text-white" wideMobile to="/contato">Entre em Contato</Link>
			</div>
		</div>
    </Slider>
);